// -- Import your page here..
const PrefixHome = () => import('@/views/form-management/prefix/PrefixHome')
const PrefixLists = () => import('@/views/form-management/prefix/PrefixList')
const PrefixCreate = () => import('@/views/form-management/prefix/PrefixCreate')
const PrefixEdit = () => import('@/views/form-management/prefix/PrefixEdit')


export default 
{   
    path: '/:orgName/form-management/prefix', 
    name: 'Prefixes List', 
    meta: { auth: true},
    redirect: '/:orgName/form-management/prefix',
    component: PrefixHome,
    children: [
        {
            path: '/:orgName/form-management/prefix', 
            name: 'Prefixes List Index', 
            meta: { label: "Index", auth: true, slug: 'prefix-list'},
            component: PrefixLists,
        },
        {
            path: '/:orgName/form-management/prefix/create', 
            name: 'Prefixes List Create', 
            meta: { label: "Create", auth: true, slug: 'create-prefix'},
            component: PrefixCreate,
        },
        {
            path: '/:orgName/form-management/prefix/:id', 
            name: 'Prefixes List Edit', 
            meta: { label: "View", auth: true, slug: 'view-prefix'},
            component: PrefixEdit,
        }
    ]
}