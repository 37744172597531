// -- Import your page here..
const DRHome = () => import('@/views/drs/document-routing/DRHome')
// const DRLists = () => import('@/views/drs/document-routing/DRLists')
const DRTabs = () => import('@/views/drs/document-routing/DRTabs')
const DRCreate = () => import('@/views/drs/document-routing/DRCreate')
// const DREdit = () => import('@/views/drs/document-routing/DREdit')


export default 
{   
    path: '/:orgName/general-access/document-routing', 
    name: 'Document Routing', 
    meta: { auth: true },
    redirect: '/:orgName/general-access/document-routing',
    component: DRHome,
    children: [
        {
            path: '/:orgName/general-access/document-routing', 
            name: 'Document Routing Index', 
            meta: { label: "Index", auth: true, slug: 'document-routing-list'},
            component: DRTabs,
        },
        {
            path: '/:orgName/general-access/document-routing/create', 
            name: 'Document Routing Create', 
            meta: { label: "Create", auth: true, slug: 'create-document-routing'},
            component: DRCreate,
        },
    ]
}