import Vue from 'vue'
import Vuex from 'vuex'
import mixin from '../mixin'



import mutations from './properties/mutations'
import getters from './properties/getters'

Vue.use(Vuex)

const my_decipher = mixin.methods.customDecipher('drs2point0')

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: true,
  dm: JSON.parse(localStorage.getItem('dm')) || false, //dark mode
  dr : localStorage.getItem('dr') ? localStorage.getItem('dr') : 'table' || 'table', //documentRouting views
  fa : localStorage.getItem('fa') ? localStorage.getItem('fa') : 'table' || 'table', //form application views
  asideShow: false,
  loaded: false,
  formDataPdfBase64: null,
  formDataPdfRoutingRefNum: null,
  ut: localStorage.getItem('_ut') || null, // user token
  te: localStorage.getItem('_te') || null, // token expiration
  ok: localStorage.getItem('_ok') || null, // organization key
  ol: localStorage.getItem('_ol') || null, // organization logo
  on: localStorage.getItem('_on') ? my_decipher(localStorage.getItem('_on')) : null || null, // organization name
  an: localStorage.getItem('_an') ? my_decipher(localStorage.getItem('_an')) : null || null, // app navigation
  ud: localStorage.getItem('_ud') ? my_decipher(localStorage.getItem('_ud')) : null || null, // user details
  un: localStorage.getItem('_un') ? my_decipher(localStorage.getItem('_un')) : null || null, // user navigation
  up: localStorage.getItem('_up') ? my_decipher(localStorage.getItem('_up')) : null || null, // user permission
  uf: null, // user notifications
  upr: localStorage.getItem('_upr')  ? my_decipher(localStorage.getItem('_upr')) : null || null, // user preference
}


export default new Vuex.Store({
    state,
    mutations,
    getters,
})