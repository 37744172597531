import Vue from 'vue'

// ! SWEET ALERT //
// https://sweetalert2.github.io/
import VueSweetalert2 from 'vue-sweetalert2';

// ! FONT AWESOME //
// https://fontawesome.com/
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// // ! PAGINATION //
// // https://www.npmjs.com/package/vue-pagination-2
import Pagination from 'vue-pagination-2';

// ! VUELIDATE //
// https://vuelidate.js.org/
import Vuelidate  from 'vuelidate';

// // ! VUE SELECT //
// // https://vue-select.org/
import vSelect from "vue-select";

// // ! TREE VIEW //
// // https://ej2.syncfusion.com/vue/documentation/treeview/getting-started/
import { TreeViewPlugin } from "@syncfusion/ej2-vue-navigations";


// ! PRETTY CHECKBOX //
// https://hamed-ehtesham.github.io/pretty-checkbox-vue/
import PrettyCheckbox from 'pretty-checkbox-vue';

// // ! V Calendar CHECKBOX //
// // https://vcalendar.io/installation.html
import VCalendar from 'v-calendar';

// // ! vue-chartjs //
// // https://vue-chartjs.org/
import CoreuiVueCharts from '@coreui/vue-chartjs'

// // ! Carousel //
// // https://gs-shop.github.io/vue-slick-carousel/#/
import VueSlickCarousel from 'vue-slick-carousel'

// // ! Vue-PDF //
// // https://www.npmjs.com/package/vue-pdf
import pdf from 'vue-pdf'

// // https://www.npmjs.com/package/vue-html2pdf
import VueHtml2pdf from 'vue-html2pdf'

// // ! PROGRESS BAR / LOADING BAR //
// // https://github.com/hilongjw/vue-progressbar
import VueProgressBar from 'vue-progressbar'

// ! Cookies
// https://www.npmjs.com/package/vue-cookies
import VueCookies from 'vue-cookies'

// ! Vue-Signature-Pad
// https://www.npmjs.com/package/vue-signature-pad
import VueSignaturePad from 'vue-signature-pad';


// ! Vue Text Mask
// https://github.com/text-mask/text-mask/tree/master/vue#readme
import MaskedInput from 'vue-text-mask'

const options = {
    color: '#0EC77A',
    failedColor: '#DC3545',
    thickness: '7px',
    transition: {
      speed: '0.2s',
      opacity: '0.6s',
      termination: 300
    },
    autoRevert: true,
    location: 'top',
    inverse: false,
    autoFinish: false
}


// ! VUE FILE AGENT //
import VueFileAgent from 'vue-file-agent';

// ! https://antdv.com/components/overview //
import { Timeline, Popover } from 'ant-design-vue';

Vue.use(Timeline);
Vue.use(Popover);
Vue.use(VueFileAgent);
Vue.use(VueProgressBar, options)
Vue.use(VueCookies);
Vue.use(VueSignaturePad);


Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

library.add(fas)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)

Vue.use(VueHtml2pdf)

Vue.component('VueSlickCarousel', VueSlickCarousel)

Vue.component('Vue-Pdf', pdf)

Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.component('masked-input', MaskedInput);

Vue.component('pagination', Pagination);

Vue.use(TreeViewPlugin);
Vue.use(PrettyCheckbox);
Vue.use(CoreuiVueCharts)

Vue.component("v-select", vSelect);

