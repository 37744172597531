// -- import your sub routes here..
import DocumentTemplateRoutes from '../drs/document-template-routes'
import DocumentTemplateFileAPIRoutes from '../drs/document-template-file-api-routes'
import DocumentTemplateSignatoryRoutes from '../drs/document-template-signatory-routes'
import DocumentTemplateCategoryRoutes from '../drs/document-template-category-routes'
import RoutingSURoutes from '../drs/routing-su-routes'
import DocumentTemplateShared from '../drs/document-template-shared-routes'
import TemplateSignatoryMatrix from '../drs/template-signatory-matrix-routes'

export default [
    {   path: '/:orgName/drs', 
        redirect:'/:orgName/drs/document-template',
        name: 'DRS', 
        meta: { auth: true },
        component: {
            render (c) { return c('router-view') }
        },
        children: [ 
            // -- append your sub routes here..
            DocumentTemplateRoutes,
            DocumentTemplateFileAPIRoutes,
            DocumentTemplateSignatoryRoutes,
            DocumentTemplateCategoryRoutes,
            RoutingSURoutes,
            DocumentTemplateShared,
            TemplateSignatoryMatrix,
        ]
    },
]