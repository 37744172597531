import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/dashboard/Dashboard.vue')
const Organization = () => import('@/views/guest/Organization')

// Authentication Views
const Login = () => import('@/views/authentication/Login');
// const Registration = () => import('@/views/authentication/Registration');

// Account Recovery
const AccountRecovery = () => import('@/views/authentication/AccountRecovery');

// Reset Password
const ResetPassword = () => import('@/views/authentication/ResetPassword');

const NotFound404 = () => import('@/views/pages/NotFound404');
const AccessForbidden403 = () => import('@/views/pages/AccessForbidden403');
const DocumentRoutingFileViewer = () => import('@/views/guest/DocumentRoutingFileViewer')


// -- Import your navigation routes here..
import GeneralRoutes from '../router/general'
import AdminRoutes from '../router/administrator'
import DRSRoutes from '../router/drs'
import FormManagementRoutes from '../router/form-management'
import SPARoutes from '../router/spa'
import GuestRoutes from '../router/guest'
import BillingReportRoutes from '../router/report'

// import SystemAdminRoutes from '../router/general/system-admin-routes'

Vue.use(Router)



const child = [
	{
		path: '/:orgName/dashboard',
		name: 'Home',
		meta: { label: 'Home', auth: true },
		component: Dashboard,
	},
]



  // -- append your navigation routes here..
const baseRoutes = child.concat(
	GeneralRoutes,
	AdminRoutes,
	DRSRoutes,
	FormManagementRoutes,
	SPARoutes,
	BillingReportRoutes,
	// SystemAdminRoutes,
	{ 	
		path: "/:orgName/access-forbidden", 
		name: 'AccessForbidden',
		component: AccessForbidden403,
		meta: { auth: true }
	},
	{ 	
		path: "*", 
		name: 'not-found',
		component: NotFound404,
		meta: { auth: true, guest: true }
	},
)




const routes = GuestRoutes.concat(	
	{
		path: '/:orgName',
		redirect: '/:orgName/login',
		meta: { guest: true }
	},
	{
		path: '/:orgName/login',
		name: 'Login',
		component: Login,
		meta: { guest: true }
	},
	{ 	
		path: "/:orgName/account-recovery", 
		name: 'AccountRecovery',
		component: AccountRecovery,
		meta: { guest: true }
	},
	{ 	
		path: "/:orgName/reset-password/:id", 
		name: 'ResetPassword',
		component: ResetPassword,
		meta: { guest: true }
	},
	{
		path: '/:orgName',
		name: 'Dashboard',
		redirect: '/:orgName/dashboard',
		component: TheContainer,
		children: baseRoutes,
		meta: { auth: true },
	},
)

const projectRouter = new  Router({
	mode: 'history', // https://router.vuejs.org/api/#mode
	linkActiveClass: 'open active',
	scrollBehavior: () => ({ y: 0 }),
	routes,
})

projectRouter.beforeEach((to, from, next) => {

	if (to.meta.organization) {
			if (store.getters.getUserDetails) {
				next({path: `/${store.getters.getOrganizationName}/dashboard`})
			}
			if(to.name == 'Organization') {
					next();
					return;
			} 
	}

	if(store.getters.getUserDetails) {
		if(store.getters.getOrganizationName != to.params.orgName) {
			// store.commit("SET_ORGANIZATION_NAME", to.params.orgName);
			next({path: `/${to.params.orgName}/dashboard`})
		}
	}
  
	if (to.meta.auth) {
		if (store.getters.getUserDetails) {
			if(!to.meta.slug || store.getters['can'](to.meta.slug)) {
				next()
				return 
			} 
			else {
				projectRouter.push({path: `/${store.getters.getOrganizationName}/access-forbidden`})
			}
		}
		else {
			store.commit("LOGOUT_USER");
			next({path: `/${store.getters.getOrganizationName}/login`})
		}
	}

	if (to.meta.guest) {
		if(to.name == 'DR File Viewer') {
			next()
			return;
		} 
		if(to.name == 'AIS to DRS') {
			next();
			return;
		}

		if (store.getters.getUserDetails) {
			next({path: `/${store.getters.getOrganizationName}/dashboard`})
		}
		else next()
	}
})

export default projectRouter
