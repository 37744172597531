// -- Import your pages here..
const DTSHome = () => import('@/views/drs/document-template-signatory/DTSHome')
const DTSLists = () => import('@/views/drs/document-template-signatory/DTSLists')
const DTSCreate = () => import('@/views/drs/document-template-signatory/DTSCreate')
const DTSEdit = () => import('@/views/drs/document-template-signatory/DTSEdit')

export default 
{   
    path: '/:orgName/drs/document-template-signatory', 
    name: 'Document Template Signatory', 
    redirect: '/:orgName/drs/document-template-signatory',
    component: DTSHome,
    children: [
        {
            path: '/:orgName/drs/document-template-signatory', 
            name: 'Document Template Signatory Index', 
            meta: { label: "Index", auth: true, slug: 'document-template-signatory-list'},
            component: DTSLists,
        },
        {
            path: '/:orgName/drs/document-template-signatory/create', 
            name: 'Document Template Signatory Create', 
            meta: { label: "Create", auth: true, slug: 'create-document-template-signatory'},
            component: DTSCreate,
        },
        {
            path: '/:orgName/drs/document-template-signatory/:id', 
            name: 'Document Template Signatory Edit', 
            meta: { label: "View", auth: true, slug: 'view-document-template-signatory'},
            component: DTSEdit,
        }
    ]
}