
import billingRoutes from "./billing-routes";
import documentRoutingRoutes from "./routing-activity-routes";

export default [
    {
        path: '/:orgName/report', 
        name: 'Report', 
        redirect: '/:orgName/report/billing',
        meta: { auth: true },
        component: {
            render (c) { return c('router-view') }
        },
        children: [ 
            billingRoutes,
            documentRoutingRoutes,
        ]

    },
]