import 'core-js/stable'
import Vue from 'vue'
//import CoreuiVuePro from '@coreui/vue-pro'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/index'
import i18n from './i18n.js'
import mixin from './mixin/index.js'



require('./libraries');
require('./axios');
require('./directives');
Vue.use(CoreuiVuePro)
Vue.mixin(mixin)
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$backend_url = process.env.VUE_APP_BACKEND_URL
Vue.prototype.$backend_url_with_auth = process.env.VUE_APP_BACKEND_URL + '/api/v1'

window.Vue = Vue;

export default new Vue({
    el: '#app',
    router,
    store,
    icons,
    i18n,
    template: '<App/>',
    components: {
        App
    },
    // created() { 
    //     this.checkStateData();
    // },
})



// new Vue({
//   el: '#app',
//   router,
//   store,
//   //CIcon component documentation: https://coreui.io/vue/docs/components/icon
//   icons,
//   i18n,
//   template: '<App/>',
//   components: {
//     App
//   }
// })
