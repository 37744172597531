
const BillingReport = () => import('@/views/report/billing/BillingReportHome')

export default 
{   
    path: '/:orgName/report/billing', 
    name: 'Billing', 
    meta: { auth: true},
    redirect: '/:orgName/report/billing',
    component: BillingReport,
    children: [
        {
            path: '/:orgName/report/billing', 
            name: 'Billing Index', 
            meta: { label: "Index", auth: true, slug: 'billing-list'},
            component: BillingReport,
        },
    ]
}