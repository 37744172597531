import mixin from '../../mixin'

const my_cipher = mixin.methods.customCipher('drs2point0')

const toggleSidebarDesktop = (state) => {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
}

const toggleSidebarMobile = (state) => {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
}

const set = (state, [variable, value]) => {
    state[variable] = value
}

const toggle = (state, variable) => {
    state[variable] = !state[variable];
    localStorage.setItem(variable, JSON.stringify(state[variable]));
}

const setDarkMode = (state, value) => {
    state.dm = value;
    localStorage.setItem('dm', JSON.stringify(value));
}

const documentRoutingViews = (state, value) => {
    state.dr = my_cipher(value);
    localStorage.setItem('dr', my_cipher(value));
}

const formApplicationViews = (state, value) => {
    state.fa = my_cipher(value);
    localStorage.setItem('fa', my_cipher(value));
}
/**
 * @param object state, this inherited from store/index.js
 * @param string user, this is from LOGIN page which is the return from axios.
 * 
 */
const LOGGED_USER = (state, user) => {
    let now = new Date()
    let expiryDate = new Date()
    let token_expiration = expiryDate.setTime(now.getTime() + user.token.expires_in * 1000)
    localStorage.setItem('_ut', user.token.token)
    localStorage.setItem('_te', token_expiration)
    state.ut = user.token.token
    state.te = token_expiration
}

const SET_USER_DETAILS = (state, value) => {
    localStorage.setItem('_ud', my_cipher(JSON.stringify(value)))
    state.ud = JSON.stringify(value)
}

const SET_USER_NAVIGATION = (state, navigation) => {
    localStorage.setItem('_un', my_cipher(JSON.stringify(navigation)))
    state.un = JSON.stringify(navigation)
}

const SET_USER_PERMISSION = (state, permission) => {
    localStorage.setItem('_up', my_cipher(JSON.stringify(permission)));
    state.up = JSON.stringify(permission)
    state.loaded = true;
}

const SET_APP_NAVIGATION = (state, value) => {
    localStorage.setItem('_an', my_cipher(JSON.stringify(value)));
    state.an = JSON.stringify(value);
}

const SET_ORGANIZATION_NAME = (state, name) => {
    localStorage.setItem('_on',  my_cipher(name.toLowerCase()))
    state.on = name.toLowerCase()
}

const SET_ORGANIZATION_KEY = (state, value) => {
    localStorage.setItem('_ok', value);
    state.ok = value
}

const SET_ORGANIZATION_LOGO = (state, value) => {
    localStorage.setItem('_ol', value);
    state.ol = value
}

const SET_USER_NOTIFICATIONS = (state, notification) => {
    state.uf = JSON.stringify(notification);
    state.loaded = true;
}

const SET_FORM_DATA_PDF_BASE64 = (state, base64PDF) => {
    state.formDataPdfBase64 = base64PDF
}

const SET_FORM_DATA_PDF_REFERENCE_NUMBER = (state, reference_number) => {
    state.formDataPdfRoutingRefNum = reference_number
}

const SET_USER_PREFERENCE = (state, value) => {
    localStorage.setItem('_upr', my_cipher(JSON.stringify(value)));
    state.upr = JSON.stringify(value);
}

const CLEAR_FORM_DATA_PDF_BASE64 = (state) => {
    state.formDataPdfBase64 = null
    state.formDataPdfRoutingRefNum = null
}

const LOGOUT_USER = (state) => {
    state.ut = null
    state.an = null
    state.ud = null
    state.un = null
    state.ok = null
    state.up = null
    state.te = null
    state.uf = null
    state.loaded = false
    localStorage.removeItem('_ut') // user token
    localStorage.removeItem('_an') // app navigation
    localStorage.removeItem('_ud') // user details
    localStorage.removeItem('_un') // user navigation
    localStorage.removeItem('_ok') // organization key
    localStorage.removeItem('_ol') // organization logo
    localStorage.removeItem('_te') // token expiration
    localStorage.removeItem('_up') // user permission
    localStorage.removeItem('_upr') //user preferences
    // localStorage.removeItem('_uf') // user notifications
    document.body.classList.remove('theme-dark', 'theme-dark-popover'); // removing the dark-mode style
    state.dm = null,
    state.dr = null,
    state.fa = null,
    localStorage.removeItem('_dm') // token expiration
    localStorage.removeItem('_dr') // user permission
    localStorage.removeItem('_fa') //user preferences

}


const CLEAR_STATE_LOCAL_STORAGE = (state) => {
    state.on = null
    state.ut = null
    state.an = null
    state.ud = null
    state.un = null
    state.ok = null
    state.up = null
    state.te = null
    state.uf = null
    state.loaded = false
    localStorage.removeItem('_on') // organization name
    localStorage.removeItem('_ut') // user token
    localStorage.removeItem('_an') // app navigation
    localStorage.removeItem('_ud') // user details
    localStorage.removeItem('_un') // user navigation
    localStorage.removeItem('_ok') // organization key
    localStorage.removeItem('_ol') // organization key
    localStorage.removeItem('_te') // token expiration
    localStorage.removeItem('_up') // user permission
    // localStorage.removeItem('_uf') // user notifications
}


export default {
    toggleSidebarDesktop,
    toggleSidebarMobile,
    set,
    toggle,
    setDarkMode,
    documentRoutingViews,
    formApplicationViews,
    LOGGED_USER,
    SET_USER_DETAILS,
    SET_USER_NAVIGATION,
    SET_USER_PERMISSION,
    SET_ORGANIZATION_NAME,
    SET_APP_NAVIGATION,
    SET_ORGANIZATION_KEY,
    SET_ORGANIZATION_LOGO,
    SET_USER_NOTIFICATIONS,
    SET_FORM_DATA_PDF_BASE64,
    SET_FORM_DATA_PDF_REFERENCE_NUMBER,
    SET_USER_PREFERENCE,
    CLEAR_FORM_DATA_PDF_BASE64,
    CLEAR_STATE_LOCAL_STORAGE,
    LOGOUT_USER,
}