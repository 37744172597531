const DocumentRoutingFileViewer = () => import('@/views/guest/DocumentRoutingFileViewer')
const BlankPageLogin = () => import('@/views/guest/BlankPageLogin')
const Organization = () => import('@/views/guest/Organization')


export default [
    {   path: '/:orgName/view-document/:id/:reference_number',
        name: 'DR File Viewer',
        component: DocumentRoutingFileViewer,
        meta: { guest: true }
    },
    {   path: '/:orgName/ais-drs-login',
        name: 'AIS to DRS',
        component: BlankPageLogin,
        meta: { guest: true }
    },
    {
        path: '/',
        name: 'Organization',
        component: Organization,
        meta: { organization: true }
    }
]