<template>
  <div>
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>


export default {
  name: 'App'
}

</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
  @import '~vue-select/dist/vue-select.css';
  @import '~sweetalert2/dist/sweetalert2.min.css';
  @import '~vue-file-agent/dist/vue-file-agent.css';
  @import "~@syncfusion/ej2-base/styles/material.css";
  @import "~@syncfusion/ej2-vue-navigations/styles/material.css";
  @import "~@syncfusion/ej2-inputs/styles/material.css";
  @import "~@syncfusion/ej2-buttons/styles/material.css";
  @import '~pretty-checkbox/src/pretty-checkbox.scss';
  @import 'ant-design-vue/lib/timeline/style';
  @import 'ant-design-vue/lib/popover/style';
</style>
