import Vue from 'vue'

Vue.directive( 'tocapitalize', {
    bind(el, _, vnode) {
        el.addEventListener('keyup', (e) => {
            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
        })
      }
})

Vue.directive( 'nospecialcharacter', {
    bind(el, _, vnode) {
        el.addEventListener('keydown', (e) => {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9 -]/g, '');
        })
        el.addEventListener('keyup', (e) => {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9 -]/g, '');
        })
    },
})

Vue.directive('numberonly', {
    bind(el, binding, vnode) {
        el.addEventListener('keydown', (e) => {
            if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
                // Allow: Ctrl+A
                (e.keyCode === 65 && e.ctrlKey === true) ||
                // Allow: dot with shift false
                (e.keyCode === 190 && e.shiftKey === false) ||
                // Allow: Ctrl+C
                (e.keyCode === 67 && e.ctrlKey === true) ||
                // Allow: Ctrl+X
                (e.keyCode === 88 && e.ctrlKey === true) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)) {
                // let it happen, don't do anything
                return
            }
            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault()
            }
        })
    }
})