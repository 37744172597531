// -- Import your pages here..
const DepartmentHome = () => import('@/views/administrator/department/DepartmentHome')
const DepartmentLists = () => import('@/views/administrator/department/DepartmentLists')
const DepartmentCreate = () => import('@/views/administrator/department/DepartmentCreate')
const DepartmentEdit = () => import('@/views/administrator/department/DepartmentEdit')

export default 
{   
    path: '/:orgName/administrator/department', 
    name: 'Department', 
    redirect: '/:orgName/administrator/department',
    component: DepartmentHome,
    children: [
        {
            path: '/:orgName/administrator/department', 
            name: 'Department Index', 
            meta: { label: "Index", auth: true, slug: 'department-list'},
            component: DepartmentLists,
        },
        {
            path: '/:orgName/administrator/department/create', 
            name: 'Department Create', 
            meta: { label: "Create", auth: true, slug: 'create-department'},
            component: DepartmentCreate,
        },
        {
            path: '/:orgName/administrator/department/:id', 
            name: 'Department Edit', 
            meta: { label: "View", auth: true, slug: 'view-department'},
            component: DepartmentEdit,
        }
    ]
}