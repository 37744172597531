// -- Import your page here..
const FDHome = () => import('@/views/form-management/form-data/FDHome')
const FDLists = () => import('@/views/form-management/form-data/FDLists')
const FDCreate = () => import('@/views/form-management/form-data/FDCreate')
const FDEdit = () => import('@/views/form-management/form-data/FDEdit')


export default 
{   
    path: '/:orgName/general-access/form-data', 
    name: 'Forms Data', 
    meta: { auth: true},
    redirect: '/:orgName/general-access/form-data',
    component: FDHome,
    children: [
        {
            path: '/:orgName/general-access/form-data', 
            name: 'Form Data Index', 
            meta: { label: "Index", auth: true, slug: 'form-data-list'},
            component: FDLists,
        },
        {
            path: '/:orgName/general-access/form-data/create', 
            name: 'Form Data Create', 
            meta: { label: "Create", auth: true, slug: 'create-form-data'},
            component: FDCreate,
        },
        {
            path: '/:orgName/general-access/form-data/:id', 
            name: 'Form Data Edit', 
            meta: { label: "View", auth: true, slug: 'view-form-data'},
            component: FDEdit,
        }
    ]
}