// -- import your sub routes here..
import DepartmentRoutes from '../administrator/department-routes'
import GroupRoutes from '../administrator/group-routes'
import ModuleRoutes from '../administrator/module-routes'

export default [
    {   path: '/:orgName/administrator', 
        redirect:'/:orgName/administrator/department',
        name: 'Administrator', 
        meta: { auth: true },
        component: {
            render (c) { return c('router-view') }
        },
        children: [ 
            // -- append your sub routes here..
            DepartmentRoutes,
            GroupRoutes,
            ModuleRoutes
        ]
    },
]