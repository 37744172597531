import Vue from 'vue'



import store from '@/store'
import router from './router'
import app from './main';
// ! AXIOS //
window.axios = require('axios').default;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + '/api/v1'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] = `Bearer ${store.getters.getUserToken}`
axios.defaults.headers.common['Organization-Name'] = store.getters.getOrganizationName
axios.defaults.headers.common['Organization-Key'] = store.getters.getOrganizationKey
axios.defaults.headers.common['Platform'] = 'WEB'

let expired = false;
let isRefreshing = false;
let failedQueue = [];
let unauthorized_promted = false;

function processQueue (error, token = null) {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })
    failedQueue = [];
}

function axiosRequestInterceptor () {
    const instance = axios.interceptors.request.use(function (config){

        if(config.url == "/logout") {
            return new Promise(function(resolve) { 
                resolve (config)
            }).finally(axiosRequestInterceptor);
        }


        config.headers.Authorization = `Bearer ${store.getters.getUserToken}`
        if(store.getters.getTokenExpiration) { 
            let currrent_date = new Date();
            let token_date = new Date(parseInt(store.getters.getTokenExpiration))
            let _diffMilliSeconds = Math.abs(token_date - currrent_date);
            let _resultMinute =  Math.floor((_diffMilliSeconds/1000)/60);
            let experimental_2 = document.cookie.split('; ').find((row) => row.startsWith('experimental_2='))?.split('=')[1];
            let _has_cookie = document.cookie.split('; ').find((row) => row.startsWith('experimental_2='))?.split('=')[1]? true : false;
            if(_resultMinute >= 25 && _resultMinute <= 40) {
                if (isRefreshing) {
                    return new Promise(function(resolve, reject) {
                        failedQueue.push({resolve, reject})
                    }).then(token => {  
                        const _headers = {
                            'Accept': "application/json, text/plain, */*",
                            'X-Requested-With': 'XMLHttpRequest',
                            'Authorization': `Bearer ${token}`,
                            'Organization-Name': store.getters.getOrganizationName,
                            'Organization-Key': store.getters.getOrganizationKey,
                            'Platform': 'WEB',
                        };
                        let newConfig = {
                            ...config,
                            headers: _headers
                        }
                        return newConfig;
                    }).catch(err => {
                        return Promise.reject(err);
                    })
                }
                axios.interceptors.request.eject(instance);
                isRefreshing = true;
                return new Promise(function(resolve) {
                    axios.post('/regenerate-token', {password: 'auto-change'}, {validateStatus: () => true}).then(response => {
                        if(response.status == 200) {
                            store.commit('LOGGED_USER', response.data.data)
                            config.headers.Authorization = `Bearer ${store.getters.getUserToken}`
                            axios.defaults.headers.common['Authorization'] = `Bearer ${store.getters.getUserToken}`
                            processQueue(null, store.getters.getUserToken);
                            isRefreshing = false;
                            resolve (config)
                        }
                    })
                    .catch((err) => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .finally(axiosRequestInterceptor);
                })
            }
            else if(_resultMinute >= 1 && _resultMinute <= 24) {
                Vue.swal({
                    customClass: 'ta-left',
                    toast: true,
                    icon: 'info',
                    title: "You seem so busy.",
                    position: 'top-right',
                    html: `For your security, we'll automatically <br>log you out in ${_resultMinute} minute(s). <br>
                    Or you can enter your password to keep on Logged in:`,
                    input: 'password',
                    inputValue: _has_cookie? 'default': '',
                    inputAttributes: {
                        autocomplete: 'off',
                        autocapitalize: 'off',
                        autocorrect: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "KEEP ME LOGGED IN",
                    cancelButtonText: "LOG ME OUT",
                    showLoaderOnConfirm: true,
                    allowEscapeKey: false,
                    preConfirm: (user_password) => {
                        if(!user_password) {
                            Vue.swal.showValidationMessage('Password cannot be empty.')
                            return 
                        }
                        axios.interceptors.request.eject(instance);
                        return new Promise(function(resolve) {
                            axios.post('/regenerate-token', {password: user_password,token: experimental_2, cookie:_has_cookie}, {validateStatus: () => true}).then(response => {
                                if(response.status == 200) {
                                    store.commit('LOGGED_USER', response.data.data)
                                    config.headers['Authorization'] = `Bearer ${store.getters.getUserToken}`
                                    axios.defaults.headers.common['Authorization'] = `Bearer ${store.getters.getUserToken}`
                                    resolve (config)
                                    Vue.swal({
                                        toast: true,
                                        position: "top-right",
                                        showConfirmButton: false,
                                        timer: 3000,
                                        icon: "success",
                                        title: "Remain Logged in.",
                                        timerProgressBar: true,
                                    })
                                }
                            }).finally(axiosRequestInterceptor);
                        })
                    }
                }).then(result => {
                    if(!result.value) {
                        axios.interceptors.request.eject(instance);
                        return new Promise(function(resolve) {
                            axios.post("/logout").then((response) => {
                                if (response.status == 200) {
                                    Vue.swal({
                                        toast: true,
                                        position: "top-right",
                                        showConfirmButton: false,
                                        timer: 3000,
                                        icon: "success",
                                        title: "Logout Success!",
                                        timerProgressBar: true,
                                    }).then( () => {
                                        store.commit("LOGOUT_USER");
                                        router.push({ path: `/${store.getters.getOrganizationName}` });
                                    });
                                }
                            }).finally(axiosRequestInterceptor);
                        })
                    }
                })
            }
            else if(_resultMinute <= 0) {
                expired = true;
                app.$Progress.fail()
                Vue.swal({
                    icon: "error",
                    title: "Session Expired!",
                    text: "This will redirect you to login page.",
                }).then(() => {
                    store.commit("LOGOUT_USER");
                    router.push({ path: `/${store.getters.getOrganizationName}` });
                })
                return config;
            }
        }
        return config;
    }, function(error) {
        return Promise.reject(error);
    })
}

function axiosResponseInterceptor () {
    const instance = axios.interceptors.response.use(function (response) {
        if(!expired) {
            if(response.status >= 300 && response.status <= 399) {
                app.$Progress.fail()
                if(response.data.message == 'Validation Error.') 
                {
                    let x = []
                    for (let i = 0; i < response.data.errors.length; i++) {
                        x.push(`<li>${response.data.errors[i]}</li>`)
                    }
                    Vue.swal({
                        icon: "error",
                        title: "Validation Error!",
                        html: `<ul style="text-align: left;">${x.join('')}</ul>`, 
                    })
                } else if (response.data.message == 'Invalid Credentials.' || response.data.message == 'User does not exists.') {
                    Vue.swal({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'error',
                        title: response.data.message,
                        timerProgressBar: true,
                    })
                } else {
                    Vue.swal({
                        icon: "error",
                        title: "Opps!",
                        text: response.data.message,
                    })
                }
            } else if(response.status == 400) {
                app.$Progress.fail()
                Vue.swal({
                    icon: "error",
                    title: "Bad Request!",
                    text: response.data.message,
                })
            } else if(response.status == 401) {
                if(!unauthorized_promted) {
                    unauthorized_promted = true;
                    app.$Progress.fail()
                    Vue.swal({
                        icon: "error",
                        title: "Unauthorized!",
                        text: "This will redirect you to login page.",
                    }).then((result) => {
                        if(result.value){
                            store.commit("LOGOUT_USER");
                            router.push({ path: `/${store.getters.getOrganizationName}` });
                        } else {
                            unauthorized_promted = false;
                        }
                    })
                } 
                return response;
            } else if(response.status == 402) {
                app.$Progress.fail()
                Vue.swal({
                    icon: "error",
                    title: "Bad Request!",
                    text: response.data.message,
                })
            } else if(response.status == 403 || response.status == 404) {
                app.$Progress.fail()
                Vue.swal({
                    icon: "error",
                    title: "Access Forbidden",
                    html: response.data.message,
                })
            } else if(response.status >= 405 && response.status <= 499) {
                app.$Progress.fail()
                Vue.swal({
                    icon: "error",
                    title: "Bad Request!",
                    text: response.data.message,
                })
            } else if(response.status >= 500){
                app.$Progress.fail()
                Vue.swal({
                    icon: "error",
                    title: "Internal Server Error!",
                    text: "Please call system administrator",
                })
            }
        }
        return response;
    }, function (error) {
        app.$Progress.fail()
        if(!expired) {
            if(error.message == "Network Error") {
                Vue.swal({
                    icon: "error",
                    title: "Network Error!",
                    text: "Something is temporarily wrong with your network connection. Please make sure you are connected to the internet and then reload your browser.",
                })
            }
            if(error.message == "Request failed with status code 403") {
                Vue.swal({
                    icon: "error",
                    title: "Access Forbidden",
                    html: error.response.data.message,
                })
            }if(error.message == "Request failed with status code 500") {
                Vue.swal({
                    icon: "error",
                    title: "Internal Server Error!",
                    text: "Please call system administrator",
                })
            }
        }
        return Promise.reject(error);
    })
}
axiosRequestInterceptor();
axiosResponseInterceptor();