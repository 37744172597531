import store from '@/store'

// -- import your sub routes here..
import FormRoutes from '../form-management/form-routes'
import FieldRoutes from '../form-management/field-routes'
import PrintOutSourceRoutes from './printout-source-routes'
import PrefixRoutes from './prefix-routes'

export default [
    {   path: '/:orgName/form-management', 
        redirect:'/:orgName/form-management/form',
        name: 'Form Management', 
        meta: { auth: true },
        component: {
            render (c) { return c('router-view') }
        },
        children: [ 
            // -- append your sub routes here..
            FormRoutes,
            FieldRoutes,
            PrintOutSourceRoutes,
            PrefixRoutes
        ]
    },
]