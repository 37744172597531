
const DTCHome = () => import('@/views/drs/document-template-category/DTCHome')
const DTCLists = () => import('@/views/drs/document-template-category/DTCLists')
const DTCCreate = () => import('@/views/drs/document-template-category/DTCCreate')
const DTCEdit = () => import('@/views/drs/document-template-category/DTCEdit')

export default 
{   
    path: '/:orgName/drs/document-template-category', 
    name: 'Document Template Category', 
    redirect: '/:orgName/drs/document-template-category',
    component: DTCHome,
    children: [
        {
            path: '/:orgName/drs/document-template-category', 
            name: 'Document Template Category Index', 
            meta: { label: "Index", auth: true, slug: 'document-template-category-list'},
            component: DTCLists,
        },
        {
            path: '/:orgName/drs/document-template-category/create', 
            name: 'Document Template Category Create', 
            meta: { label: "Create", auth: true, slug: 'create-document-template-category'},
            component: DTCCreate,
        },
        {
            path: '/:orgName/drs/document-template-category/:id', 
            name: 'Document Template Category Edit', 
            meta: { label: "View", auth: true, slug: 'view-document-template-category'},
            component: DTCEdit,
        }
    ]
}