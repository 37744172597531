// -- Import your page here..
const UserHome = () => import('@/views/general/user/UserHome')
const UserLists = () => import('@/views/general/user/UserLists')
const UserCreate = () => import('@/views/general/user/UserCreate')
const UserEdit = () => import('@/views/general/user/UserEdit')


export default 
{   
    path: '/:orgName/general-access/user', 
    name: 'Users', 
    meta: { auth: true},
    redirect: '/:orgName/general-access/user',
    component: UserHome,
    children: [
        {
            path: '/:orgName/general-access/user', 
            name: 'User Index', 
            meta: { label: "Index", auth: true, slug: 'user-list'},
            component: UserLists,
        },
        {
            path: '/:orgName/general-access/user/create', 
            name: 'User Create', 
            meta: { label: "Create", auth: true, slug: 'create-user'},
            component: UserCreate,
        },
        {
            path: '/:orgName/general-access/user/:id', 
            name: 'User Edit', 
            meta: { label: "View", auth: true, slug: 'view-user'},
            component: UserEdit,
        }
    ]
}