// -- Import your pages here..
const DTSHHome = () => import('@/views/drs/document-template-shared/DTSHHome')
const DTSHLists = () => import('@/views/drs/document-template-shared/DTSHLists')
const DTSHCreate = () => import('@/views/drs/document-template-shared/DTSHCreate')
const DTSHEdit = () => import('@/views/drs/document-template-shared/DTSHEdit')

export default 
{   
    path: '/:orgName/drs/document-template-shared', 
    name: 'Document Template Shared', 
    redirect: '/:orgName/drs/document-template-shared',
    component: DTSHHome,
    children: [
        {
            path: '/:orgName/drs/document-template-shared', 
            name: 'Document Template Shared Index', 
            meta: { label: "Index", auth: true, slug: 'document-template-shared-list'},
            component: DTSHLists,
        },
        {
            path: '/:orgName/drs/document-template-shared/create', 
            name: 'Document Template Shared Create', 
            meta: { label: "Create", auth: true, slug: 'create-document-template-shared'},
            component: DTSHCreate,
        },
        {
            path: '/:orgName/drs/document-template-shared/:id', 
            name: 'Document Template Shared Edit', 
            meta: { label: "View", auth: true, slug: 'view-document-template-shared'},
            component: DTSHEdit,
        }
    ]
}