// -- Import your pages here..
const DTHome = () => import('@/views/drs/document-template/DTHome')
const DTLists = () => import('@/views/drs/document-template/DTLists')
const DTCreate = () => import('@/views/drs/document-template/DTCreate')
const DTEdit = () => import('@/views/drs/document-template/DTEdit')

export default 
{   
    path: '/:orgName/drs/document-template', 
    name: 'Document Template', 
    redirect: '/:orgName/drs/document-template',
    component: DTHome,
    children: [
        {
            path: '/:orgName/drs/document-template', 
            name: 'Document Template Index', 
            meta: { label: "Index", auth: true, slug: 'document-template-list'},
            component: DTLists,
        },
        {
            path: '/:orgName/drs/document-template/create', 
            name: 'Document Template Create', 
            meta: { label: "Create", auth: true, slug: 'create-document-template'},
            component: DTCreate,
        },
        {
            path: '/:orgName/drs/document-template/:id', 
            name: 'Document Template Edit', 
            meta: { label: "View", auth: true, slug: 'view-document-template'},
            component: DTEdit,
        }
    ]
}