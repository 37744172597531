// -- Import your pages here..
const GroupHome = () => import('@/views/administrator/group/GroupHome')
const GroupLists = () => import('@/views/administrator/group/GroupLists')
const GroupCreate = () => import('@/views/administrator/group/GroupCreate')
const GroupEdit = () => import('@/views/administrator/group/GroupEdit')

export default 
{   
    path: '/:orgName/administrator/group', 
    name: 'Group', 
    redirect: '/:orgName/administrator/group',
    component: GroupHome,
    children: [
        {
            path: '/:orgName/administrator/group', 
            name: 'Group Index', 
            meta: { label: "Index", auth: true, slug: 'group-list'},
            component: GroupLists,
        },
        {
            path: '/:orgName/administrator/group/create', 
            name: 'Group Create', 
            meta: { label: "Create", auth: true, slug: 'create-group'},
            component: GroupCreate,
        },
        {
            path: '/:orgName/administrator/group/:id', 
            name: 'Group Edit', 
            meta: { label: "View", auth: true, slug: 'view-group'}, 
            component: GroupEdit,
        }
    ]
}