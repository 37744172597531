
export default {
    methods: {
        /**
         * This function check the validity of user's current token
         * Not using // -- August 6 2021, Due to Duplication of error upon reload.
         * Token Refresh only on axios requset interceptor
         * Genesis Ivan,
         */
        checkTokenExpiration: function() {
            if(this.$store.getters.getTokenExpiration) { 
                let currrent_date = new Date();
                let token_date = new Date(parseInt(this.$store.getters.getTokenExpiration))
                let experimental_2 = this.$cookies.get('experimental_2');
                let _has_cookie = this.$cookies.get('experimental_2')? true : false;

                let _diffMilliSeconds = Math.abs(token_date - currrent_date);
                let _resultMinute =  Math.floor((_diffMilliSeconds/1000)/60)
                if(_resultMinute >= 25 && _resultMinute <= 40) {
                    axios.post('/regenerate-token', {password: 'auto-change'}, {validateStatus: () => true}).then(response => {
                        if(response.status == 200) {
                            this.$store.commit('LOGGED_USER', response.data.data)
                            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.getUserToken
                        }
                    })
                } else if(_resultMinute >= 4 && _resultMinute <= 24) {
                    this.$swal({
                        customClass: 'ta-left',
                        toast: true,
                        icon: 'info',
                        position: 'top-right',
                        html: `For your security, we'll automatically <br>log you out in ${_resultMinute} minute(s). <br>
                        Or you can enter your password to keep on Logged in:`,
                        input: 'password',
                        inputValue: _has_cookie? 'default': '',
                        inputAttributes: {
                            autocomplete: 'off',
                            autocapitalize: 'off',
                            autocorrect: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "LOG ME OUT",
                        cancelButtonText: "KEEP ME LOGGED IN",
                    }).then(result => {
                        if(result.value) {
                            axios.post("/logout").then((response) => {
                                if (response.status == 200) {
                                    this.$swal({
                                        toast: true,
                                        position: "top-right",
                                        showConfirmButton: false,
                                        timer: 3000,
                                        icon: "success",
                                        title: "Logout Success!",
                                    }).then( () => {
                                        this.$store.commit("LOGOUT_USER");
                                        this.$router.push({ path: `/${store.getters.getOrganizationName}` });
                                    });
                                }
                            })
                        } else {
                            const _userPassword = document.querySelector('.user-password');
                            axios.post('/regenerate-token', {password: _userPassword.value,token: experimental_2, cookie:_has_cookie}, {validateStatus: () => true}).then(response => {
                                if(response.status == 200) {
                                    this.$swal({
                                        toast: true,
                                        position: "top-right",
                                        showConfirmButton: false,
                                        timer: 3000,
                                        icon: "success",
                                        title: "Logged in keep.",
                                    }).then(() => {
                                        this.$store.commit('LOGGED_USER', response.data.data)
                                        window.axios.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.getUserToken}`
                                    })
                                }
                            })
                        }
                    })
                } else if (_resultMinute <= 3) {
                    this.$swal({
                        icon: "error",
                        title: "Session Expired!",
                        text: "This will redirect you to login page.",
                    }).then(() => {
                        this.$store.commit("LOGOUT_USER");
                        this.$router.push({ path: `/${this.$store.getters.getOrganizationName}/login` });
                    })
                }
            }
        },

        /**
         * Pass a status and will return a color
         * This can be use in tables
         * 
         * @param string param
         * @return string
         */
        getColorStatus: function(param) {
            switch (param) {
                //You can add statuses here.
                case 'Active' : return 'success';
                case 'Inactive' : return 'secondary';
                case 'Archived' : return 'secondary';
                case 'Pending' : return 'warning';
                case 'Banned' : return 'danger';

                // Document Status
                case 'draft' : return 'dark';
                case 'signing' : return 'primary';
                case 'completed' : return 'success';
                case 'cancelled' : return 'danger';

                // Document Routing Tab
                case 'draft-list' : return 'dark';
                case 'signing-list' : return 'primary';
                case 'processing-list' : return 'primary';
                case 'completed-list' : return 'success';
                case 'cancelled-list' : return 'danger';

                // Document Routing Timeline
                case 't-past' : return 'blue';
                case 't-present' : return 'green';
                case 't-future' : return 'gray';
                case 't-cancelled' : return 'red';

                case 'Yes' : return 'success';
                case 'No' : return 'danger';

                case 'POST' : return 'danger';
                case 'GET' : return 'success';

                case 'locked' : return 'dark';
                case 'unlocked' : return 'primary';

                // boolean values 
                case '0' : return 'warning';
                case '1' : return 'success';
                // boolean values 
                case false : return 'warning';
                case true : return 'success';

                //Document Categories 
                case '5' : return 'label-red';

                case 'basic': return 'warning'
                case 'advanced': return 'danger'
                
                case 'user': return 'warning'
                case 'super-user': return 'danger'

                // SINGLE REQUEST TRACKING
                case 'pending' : return 'secondary';
                case 'signed' : return 'primary';
                case 'accepted' : return 'success';
                case 'rejected' : return 'danger';

                case 'used' : return 'success'
                case 'unused' : return 'danger'

                default: 'primary'
            }
        },

        /**
         * Pass a integer status and will return a string status
         * This can be use in tables
         * 
         * @param int param
         * @return string
         */
        getDocumentStatus: function(status) {
            return (status == 1) ? 'draft' : 
                    (status == 2) ? 'signing' :
                    (status == 3) ? 'completed' :
                    'cancelled'; 
        },

        /**
         * This function get sidebar navigations
         * 
         */
        getUserNavigation: function () {
            let user_navigation = [];
            let user_permission = [];
            if(this.$store.getters.getTokenExpiration) {
                axios.get('/permissions/user-access/'+this.$store.getters.getUserDetails.id, {validateStatus: () => true})
                .then(response => {
                    if(response.status == 200) {
                        if(response.data.data) {
                            for(let i = 0; i < response.data.data.length; i++) { //GROUP
                                user_navigation.push(response.data.data[i]);
                                for(let m = 0; m < response.data.data[i].modules_view.length; m++) { // MODULE
                                    for(let ma = 0; ma < response.data.data[i].modules_view[m].modules_access_view.length; ma++) { // MODULE ACCESS
                                        user_permission.push(response.data.data[i].modules_view[m].modules_access_view[ma].slug)
                                    }
                                }
                            }
                            this.$store.commit('SET_USER_NAVIGATION', user_navigation)
                            this.$store.commit('SET_USER_PERMISSION', user_permission)
                        } else {
                            this.$store.commit('SET_USER_NAVIGATION', [])
                            this.$store.commit('SET_USER_PERMISSION', [])
                        }
                    }
                })
            }
        },

        /**
         * This function get sidebar navigations
         * 
         */
        getAppNavigation: function () {
            if(this.$store.getters.getTokenExpiration) {
                axios.get('/app-navigation', {validateStatus: () => true})
                .then(response => {
                    if(response.status == 200) {
                        this.$store.commit('SET_APP_NAVIGATION', response.data.data)
                    }
                })
            }
        },

        /**
         * This function check the state data from local storage. 
         * If some of the keys has been deleted / removed it will automatically clear all and redirect to login.
         */
        checkStateData: function(){
            if(localStorage.getItem('_ud')) {
                if( !localStorage.getItem('_an') ||
                    !localStorage.getItem('_te') ||
                    !localStorage.getItem('_on') ||
                    !localStorage.getItem('_ut') ||
                    !localStorage.getItem('_ok') ||
                    !localStorage.getItem('_un') ||
                    !localStorage.getItem('_ud') ) {
                        this.$store.commit("LOGOUT_USER");
                        this.$router.push({ path: `/${this.$store.getters.getOrganizationName}` });
                }
            }
        },

        /**
         * This function change the date format base on the location of the current user.
         * eg. if ph, this will be GMT +8
         * eg. if india, this will be GMT +5:30
         * and so on...
         *
         * @param _date mysql date 2021-05-10 01:53:04
         * @returns May 10, 2021 at 9:53:04 
         */
        changeDateFormat: function(_date) {
            if(_date) {
                let _newDate = new Date(_date)
                let _newDateOptions = { dateStyle: 'long',  timeStyle	: "medium"}
                return _newDate.toLocaleString('en', _newDateOptions);
            } else {
                return ''
            }
        },

        /**
         * This function check change the date format
         * @param _date string date 2022-11-12
         * @returns November 12, 2022
         */
         changeDateFormatV2: function(_date) {
            if(_date) {
                let d = new Date(_date);
                let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
                let mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
                let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
                return `${mo} ${da}, ${ye}`;
            } else {
                return ''
            }
        },

        /**
         * This function check change the date format
         * @param _number string numer 24001.34
         * @returns 24,001.34
         */
        numberFormatInForm: function (_number) {
            if(parseInt(_number) > 0) {
                return new Intl.NumberFormat(undefined,{minimumFractionDigits: 2}).format(_number);
            } else {
                return 0;
            }
        },

        /**
         * This function will generate a date and time base on current users timezon
         * also return the value in mysql format
         * @returns 09-23-2024 14:00:56
         */
        currentDateTimeWithUsersTimeZone: function () {
            const formatedMysqlString = (new Date ((new Date((new Date(new Date())).toISOString() )).getTime() - ((new Date()).getTimezoneOffset()*60000))).toISOString().slice(0, 19).replace('T', ' ');
            return formatedMysqlString;
        },
        
        /**
         * Custom Cipher for encrypting sensitive data
         * @param _salt your custom salt
         * @returns ecrypted value
         */
        customCipher: function(salt)  {
            const textToChars = text => text.split('').map(c => c.charCodeAt(0));
            const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
            const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        },

        /**
         * Custom Decipher for decrypting sensitive data
         * @param _salt your custom salt
         * @returns decrypted value
         */
        customDecipher: function(salt)  {
            const textToChars = text => text.split('').map(c => c.charCodeAt(0));
            const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
            return encoded => encoded.match(/.{1,2}/g)
                .map(hex => parseInt(hex, 16))
                .map(applySaltToChar)
                .map(charCode => String.fromCharCode(charCode))
                .join('');
        },
        
        /**
         * Users notification
         * @returns data
         */
        getUserNotification: function () {
            if(this.$store.getters.getTokenExpiration) {
                axios.get('/notifications', {validateStatus: () => true}).then(response => {
                    if(response.status == 200) {
                        this.$store.commit('SET_USER_NOTIFICATIONS', response.data.data)
                    }
                })
            }
        },

        /**
         * Input validator of vuelidate. 
         * @param object your object in the form
         * @param fieldName the specific fieldName to validate
         * @returns true / false
         * 
         */
        checkIfValid: function (object, fieldName) {
            const field = object[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },

        
        /**
         * Generate PDF File from a Base64 String
         * @param base64 your the string. Include the data:application/pdf;base6 and such...
         * @returns blob/file binary
         * 
         */
        generatePDFFromBase64: function (base64) { 
            let block = base64.split(";");
            let contentType = block[0].split(":")[1];
            let realData = block[1].split(",")[1];
            contentType = contentType || '';
            let sliceSize = sliceSize || 512; 
            let byteCharacters = atob(realData);
            let byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                let slice = byteCharacters.slice(offset, offset + sliceSize);
                let byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            return new Blob(byteArrays, {type: contentType});
        },

        /**
         * Generate the correct term for bytes
         * @param bytes
         * @returns rounded value
         * 
         */
        formatBytes(bytes, decimals = 2) {
            if (!+bytes) return '0 Bytes'
            const k = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            const i = Math.floor(Math.log(bytes) / Math.log(k))
            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
        },

        /**
         * This function is used to encode a normal string into uri in able to pass it as variable in the url.
         * @param string
         * @returns encoded params
         * 
         */
        paramEncoder: function(string) {
            let encoded_base64 = btoa(string);
            let encoded_uri = encodeURIComponent(encoded_base64);
            return encoded_uri;
        },

        /**
         * This function is used to decode a uri into normal string
         * @param encoded url
         * @returns decoded params
         * 
         */
        paramDecoder: function(uri) {
            let decoded_uri = decodeURIComponent(uri)
            let decoded_base64 = atob(decoded_uri)
            return decoded_base64;
        },
        
        /**
         * This function Logout a User.
         * 
         */
        logoutUser: function(organization_name) {
            axios.post("/logout").then((response) => {
                // if (response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: "top-right",
                        timer: 3000,
                        icon: "success",
                        title: "Logout Success!",
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }).then( () => {
                        this.$store.commit("LOGOUT_USER");
                        this.$store.commit('setDarkMode', false);
                        document.body.classList.remove('theme-dark', 'theme-dark-popover'); 
                        this.$router.push({ path: `/${organization_name}/login` });
                    });
                // }
            });
        },
        
    },
}