// -- Import your pages here..
const MySettings = () => import('@/views/spa/MySettings')
const MyAccount = () => import('@/views/spa/MyAccount')

export default [
    {   path: '/:orgName/me',
        name: 'Profile',
        component: MyAccount,
        meta: { auth: true }
    },
    {   path: '/:orgName/settings',
        name: 'Settings',
        component: MySettings,
        meta: { auth: true }
    },
]