// -- Import your page here..
const RoutingSUHome = () => import('@/views/drs/routing-su/RoutingSUHome')
const RoutingSUList = () => import('@/views/drs/routing-su/RoutingSUList')
const RoutingSUManage = () => import('@/views/drs/routing-su/RoutingSUManage')


export default 
{   
    path: '/:orgName/drs/routing-su', 
    meta: { auth: true},
    name: 'Routing SU Home', 
    component: RoutingSUHome,
    redirect: '/:orgName/drs/routing-su',
    children: [
        {
            path: '/:orgName/drs/routing-su', 
            name: 'Routing SU Index', 
            meta: { label: "Index", auth: true, slug: 'routing-su-list'},
            component: RoutingSUList,
        },
        {   
            path: '/:orgName/drs/routing-su/manage', 
            name: 'Routing SU Manage', 
            meta: { label: "Manage Routing", auth: true, slug: 'routing-su-manage'},
            component: RoutingSUManage,
        }
        // {
        //     path: '/:orgName/general-access/user/create', 
        //     name: 'User Create', 
        //     meta: { label: "Create", auth: true, slug: 'create-user'},
        //     component: UserCreate,
        // },
        // {
        //     path: '/:orgName/general-access/user/:id', 
        //     name: 'User Edit', 
        //     meta: { label: "View", auth: true, slug: 'view-user'},
        //     component: UserEdit,
        // }
    ]
}