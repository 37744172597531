
const DocumentRoutingReport = () => import('@/views/report/document-routing-activity/ActivityReportHome')

export default 
{   
    path: '/:orgName/report/document-routing-activity', 
    name: 'Document Routing Activity', 
    meta: { auth: true},
    redirect: '/:orgName/report/document-routing-activity',
    component: DocumentRoutingReport,
    children: [
        {
            path: '/:orgName/report/document-routing-activity', 
            name: 'Document Routing Activity Index', 
            meta: { label: "Index", auth: true, slug: 'activity-report-list'},
            component: DocumentRoutingReport,
        },
    ]
}