// -- Import your page here..
const FormHome = () => import('@/views/form-management/form/FormHome')
const FormLists = () => import('@/views/form-management/form/FormLists')
const FormCreate = () => import('@/views/form-management/form/FormCreate')
const FormEdit = () => import('@/views/form-management/form/FormEdit')


export default 
{   
    path: '/:orgName/form-management/form', 
    name: 'Forms', 
    meta: { auth: true},
    redirect: '/:orgName/form-management/form',
    component: FormHome,
    children: [
        {
            path: '/:orgName/form-management/form', 
            name: 'Form Index', 
            meta: { label: "Index", auth: true, slug: 'form-list'},
            component: FormLists,
        },
        {
            path: '/:orgName/form-management/form/create', 
            name: 'Form Create', 
            meta: { label: "Create", auth: true, slug: 'create-form'},
            component: FormCreate,
        },
        {
            path: '/:orgName/form-management/form/:id', 
            name: 'Form Edit', 
            meta: { label: "View", auth: true, slug: 'view-form'},
            component: FormEdit,
        }
    ]
}