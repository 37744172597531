import store from '@/store'

// -- import your sub routes here..
import UserRoutes from '../general/user-routes'
import DocumentRoutingRoutes from '../general/document-routing-routes'
import FormDataRoutes from '../general/form-data-routes'

export default [
    {   path: '/:orgName/general-access', 
        redirect:'/:orgName/general-access/user',
        name: 'General Access', 
        meta: { auth: true },
        component: {
            render (c) { return c('router-view') }
        },
        children: [ 
            // -- append your sub routes here..
            UserRoutes,
            DocumentRoutingRoutes,
            FormDataRoutes
        ]
    },
]