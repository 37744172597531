// -- Import your pages here..
const DTFAHome = () => import('@/views/drs/document-template-file-api/DTFAHome')
const DTFALists = () => import('@/views/drs/document-template-file-api/DTFALists')
const DTFACreate = () => import('@/views/drs/document-template-file-api/DTFACreate')
const DTFAEdit = () => import('@/views/drs/document-template-file-api/DTFAEdit')

export default 
{   
    path: '/:orgName/drs/document-template-file-api', 
    name: 'Document File API', 
    redirect: '/:orgName/drs/document-template-file-api',
    component: DTFAHome,
    children: [
        {
            path: '/:orgName/drs/document-template-file-api', 
            name: 'Document File API Index', 
            meta: { label: "Index", auth: true, slug: 'document-template-file-api-list'},
            component: DTFALists,
        },
        {
            path: '/:orgName/drs/document-template-file-api/create', 
            name: 'Document File API Create', 
            meta: { label: "Create", auth: true, slug: 'create-document-template-file-api'},
            component: DTFACreate,
        },
        {
            path: '/:orgName/drs/document-template-file-api/:id', 
            name: 'Document File API Edit', 
            meta: { label: "View", auth: true, slug: 'view-document-template-file-api'},
            component: DTFAEdit,
        }
    ]
}