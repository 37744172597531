// -- Import your pages here..
const TSMHome = () => import('@/views/drs/template-signatory-matrix/TSMHome')
const TSMLists = () => import('@/views/drs/template-signatory-matrix/TSMLists')
const TSMCreate = () => import('@/views/drs/template-signatory-matrix/TSMCreate')
const TSMEdit = () => import('@/views/drs/template-signatory-matrix/TSMEdit')

export default 
{   
    path: '/:orgName/drs/template-signatory-matrix', 
    name: 'Template Signatory Matrix', 
    redirect: '/:orgName/drs/template-signatory-matrix',
    component: TSMHome,
    children: [
        {
            path: '/:orgName/drs/template-signatory-matrix', 
            name: 'Template Signatory Matrix Index', 
            meta: { label: "Index", auth: true, slug: 'template-signatory-matrix-list'},
            component: TSMLists,
        },
        {
            path: '/:orgName/drs/template-signatory-matrix/create', 
            name: 'Template Signatory Matrix Create', 
            meta: { label: "Create", auth: true, slug: 'create-template-signatory-matrix'},
            component: TSMCreate,
        },
        {
            path: '/:orgName/drs/template-signatory-matrix/:id', 
            name: 'Template Signatory Matrix Edit', 
            meta: { label: "View", auth: true, slug: 'view-template-signatory-matrix'},
            component: TSMEdit,
        }
    ]
}