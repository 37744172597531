// -- Import your page here..
const PrintOutSourceHome = () => import('@/views/form-management/printout-source/PrintOutSourceHome')
const PrintOutSourceLists = () => import('@/views/form-management/printout-source/PrintOutSourceLists')
const PrintOutSourceCreate = () => import('@/views/form-management/printout-source/PrintOutSourceCreate')
const PrintOutSourceEdit = () => import('@/views/form-management/printout-source/PrintOutSourceEdit')


export default 
{   
    path: '/:orgName/form-management/printout-source', 
    name: 'PrintOut Sources', 
    meta: { auth: true},
    redirect: '/:orgName/form-management/printout-source',
    component: PrintOutSourceHome,
    children: [
        {
            path: '/:orgName/form-management/printout-source', 
            name: 'PrintOut Source Index', 
            meta: { label: "Index", auth: true, slug: 'printout-source-list'},
            component: PrintOutSourceLists,
        },
        {
            path: '/:orgName/form-management/printout-source/create', 
            name: 'PrintOut Source Create', 
            meta: { label: "Create", auth: true, slug: 'create-printout-source'},
            component: PrintOutSourceCreate,
        },
        {
            path: '/:orgName/form-management/printout-source/:id', 
            name: 'PrintOut Source Edit', 
            meta: { label: "View", auth: true, slug: 'view-printout-source'},
            component: PrintOutSourceEdit,
        }
    ]
}