// -- Import your page here..
const FieldHome = () => import('@/views/form-management/field/FieldHome')
const FieldLists = () => import('@/views/form-management/field/FieldLists')
const FieldCreate = () => import('@/views/form-management/field/FieldCreate')
const FieldEdit = () => import('@/views/form-management/field/FieldEdit')


export default 
{   
    path: '/:orgName/form-management/field', 
    name: 'Fields', 
    meta: { auth: true},
    redirect: '/:orgName/form-management/field',
    component: FieldHome,
    children: [
        {
            path: '/:orgName/form-management/field', 
            name: 'Field Index', 
            meta: { label: "Index", auth: true, slug: 'field-list'},
            component: FieldLists,
        },
        {
            path: '/:orgName/form-management/field/create', 
            name: 'Field Create', 
            meta: { label: "Create", auth: true, slug: 'create-field'},
            component: FieldCreate,
        },
        {
            path: '/:orgName/form-management/field/:id', 
            name: 'Field Edit', 
            meta: { label: "View", auth: true, slug: 'view-field'},
            component: FieldEdit,
        }
    ]
}