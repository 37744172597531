// -- Import your pages here..
const ModuleHome = () => import('@/views/administrator/module/ModuleHome')
const ModuleLists = () => import('@/views/administrator/module/ModuleLists')
const ModuleCreate = () => import('@/views/administrator/module/ModuleCreate')
const ModuleEdit = () => import('@/views/administrator/module/ModuleEdit')

export default 
{   
    path: '/:orgName/administrator/module', 
    name: 'Module', 
    redirect: '/:orgName/administrator/module',
    component: ModuleHome,
    children: [
        {
            path: '/:orgName/administrator/module', 
            name: 'Module Index', 
            meta: { label: "Index", auth: true, slug: 'module-list'},
            component: ModuleLists,
        },
        {
            path: '/:orgName/administrator/module/create', 
            name: 'Module Create', 
            meta: { label: "Create", auth: true, slug: 'create-module'},
            component: ModuleCreate,
        },
        {
            path: '/:orgName/administrator/module/:id', 
            name: 'Module Edit', 
            meta: { label: "View", auth: true, slug: 'view-module'},
            component: ModuleEdit,
        }
    ]
}